<template>
  <div class="logs">
    <!-- 操作日志
     -->
     <div class="searchBtn">
            <!-- <div class="inputContent"> -->
            <el-input placeholder="请输入操作人员" v-model="searchInfo.name" clearable size="small">
            </el-input>
            <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small"
                placeholder="请选择组织机构" :show-all-levels="false"
                :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"></el-cascader>
                <el-button type="primary" size="small" @click="search">搜索</el-button>
    </div>
    <div class="tableContent">
        <el-table :data="tableData" border style="width: 100%" size="small" >
                <!-- <el-table-column type="selection">

                </el-table-column> -->
                <!-- <el-table-column label="序号" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.id }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="id" label="序号" width="80">
                </el-table-column>
                <el-table-column prop="nickname" label="日志类型" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.operationType === 1">登录</span>
                        <span v-if="scope.row.operationType === 2">新增</span>
                        <span v-if="scope.row.operationType === 3">修改</span>
                        <span v-if="scope.row.operationType === 4">删除</span>
                        <span v-if="scope.row.operationType === 5">支付</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userId" label="操作用户ID" width="100">
                </el-table-column>
                <el-table-column prop="username" label="操作用户登录名" width="180">
                </el-table-column>
                <el-table-column prop="deptName" label="组织机构" width="180">
                </el-table-column>
                <!-- <el-table-column prop="nickname" label="IP" >
                </el-table-column> -->
                <el-table-column prop="createTime" label="记录时间" width="180">
                </el-table-column>
                <el-table-column prop="name" label="日志信息" >
                </el-table-column>
        </el-table>
        <div class="block" style="margin-top: 20px;">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
    import {getLogs} from '@/api/system/logs'
    import { getOrganizationList } from '@/api/system/organization'
export default {
    data() {
        return{
            searchInfo: {
                name: null,
                deptId: null
            },
            OrganizationsList: [],
            tableData: [],
            paging: {
                p: 1,
                ps: 20,
                total: 0
            }
        }
    },
    methods: {
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            console.log('组织机构');
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
                // this.deleteNullChildren(this.OrganizationsList)
            }
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getLogsList();
        },
        // 获取日志列表
        async getLogsList() {
            let info = {...this.searchInfo,p: this.paging.p,ps: this.paging.ps};
            if(info.deptId === -1) {
                info.deptId = null;
            }
            const res = await getLogs(info);
            console.log(res);
            if(res.code === 200) {
                this.tableData = res.data.records;
                this.paging.total = res.data.total;
            }
        },
        search() {
            this.paging.p = 1;
            this.getLogsList()
        }
    },
    created() {
        this.getLogsList();
        this.getOrganizationsList();
    }
}
</script>

<style lang="scss" scoped>
    .logs{
        padding: 20px;
        .tableContent{
            margin-top: 10px;
        }
    }

</style>